
/* ----------------------------------------
    Imports
---------------------------------------- */

import {
  Component,
  Prop,
  Vue
}

  from 'vue-property-decorator';
import Slider from '../../../fruitpackage/components/Slider.vue';
import Thumb from '@part/elements/Thumb.vue';
/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  components: {
    Slider,
    Thumb
  },

  computed: {
    shuffledData() {
      return this.shuffleArr(this.data.museums);
    }
  },

  methods: {
    shuffleArr(a) {
      let j, x, i;

      for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
      }

      a > 10 && a.slice(0, 10);
      return a;
    }
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class InTheHood extends Vue { }

