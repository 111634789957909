import { render, staticRenderFns } from "./Thumb.vue?vue&type=template&id=409b3780&scoped=true&"
import script from "./Thumb.vue?vue&type=script&lang=ts&"
export * from "./Thumb.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "409b3780",
  null
  
)

export default component.exports