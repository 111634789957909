
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';

import MyNightButton from '@part/elements/MyNightButton.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
  components: { MyNightButton },
  data() {
    return {
      hidden: false
    };
  },
  props: {
    favButton: {
      type: Boolean,
      required: false,
      default: true
    },
    size: {
      type: String,
      required: false,
      default: 'small'
    },
    data: {
      type: Object,
      required: false
    }
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class MuseumThumb extends Vue {}
